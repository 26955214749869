import React, { useContext, useMemo } from 'react'
import { Chart } from 'react-google-charts'

import LensIcon from '@mui/icons-material/Lens'
import { useTheme } from '@mui/material'
import { Box } from '@mui/system'
import Text from 'components/ui/Text'
import { ThemeContext } from 'context/themeMode'
import { Point } from 'types/point'
import {
  createValidatorsBarChartOptions,
  generateValidatorsBarChartData,
} from 'utils/validatorsBarChart'

import map from 'lodash/map'

interface Props {
  chartDataRegistered?: Point[]
  chartDataClosed?: Point[]
}
function ValidatorsBarChart({
  chartDataRegistered = [],
  chartDataClosed = [],
}: Props) {
  const materialTheme = useTheme()

  const theme = useContext(ThemeContext)

  const statsData = useMemo(
    () =>
      chartDataRegistered.length && chartDataClosed.length
        ? generateValidatorsBarChartData(
            chartDataRegistered,
            chartDataClosed,
            theme.mode,
          )
        : [],
    [chartDataRegistered, chartDataClosed, theme],
  )

  const xTicks = useMemo(
    () => map(chartDataRegistered, point => new Date(point?.day)),
    [chartDataRegistered],
  )

  const options = createValidatorsBarChartOptions(xTicks)

  const renderLegend = useMemo(() => {
    return (
      <Box
        display="flex"
        gap={3}
        sx={{
          fontSize: '12px',
          position: 'absolute',
          top: 16,
          left: 0,
        }}
      >
        <Box alignItems="center" display="flex" gap={1}>
          <LensIcon color="primary" sx={{ fontSize: '8px' }} />
          Registered
        </Box>
        <Box alignItems="center" display="flex" gap={1}>
          <LensIcon color="error" sx={{ fontSize: '8px' }} />
          Closed
        </Box>
      </Box>
    )
  }, [])

  if (!statsData.length)
    return (
      <Box alignItems="center" display="flex" flex={1}>
        <Text secondary>No available data</Text>
      </Box>
    )

  return (
    <Box height="100%" position="relative" width="100%">
      {renderLegend}
      <Chart
        chartType="ColumnChart"
        data={statsData}
        height="100%"
        options={{
          ...options,
          // @ts-ignore
          baselineColor: materialTheme.palette.colors.border.default,
          colors: [
            materialTheme.palette.error.main,
            materialTheme.palette.primary.main,
          ],
        }}
      />
    </Box>
  )
}

export default ValidatorsBarChart
