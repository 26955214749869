import React from 'react'

import CircleIcon from '@mui/icons-material/Circle'
import SyncIcon from '@mui/icons-material/Sync'
import {
  Box,
  Button,
  Divider,
  Popover,
  Typography,
  useTheme,
} from '@mui/material'
import { AppVersionPopup } from 'components/appVersionPopup'
import { NodeInfoPopup } from 'components/nodeInfoPopup'
import Text from 'components/ui/Text'
import { WorkingNodeLoader } from 'components/workingNodeLoader'
import { useAppContext } from 'context/AppContext'
import { StakingPeriod } from 'graphql/stakingTerms/types'

import { PopoverContainer } from './styles'

interface Props {
  active: boolean
  canResetNode?: boolean
  period?: StakingPeriod
  projectedRewards?: number
  onReset?: () => void
}

function IsNodeActive({
  active,
  canResetNode,
  period,
  projectedRewards,
  onReset,
}: Props) {
  const materialTheme = useTheme()
  const { me } = useAppContext()
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box alignItems="center" display="flex" gap={1}>
        <Text title1>Node Details</Text>
        <NodeInfoPopup period={period} projectedRewards={projectedRewards} />
      </Box>

      <Box alignItems="center" display="flex" gap={2} height="20px">
        {me?.nodeVersion && (
          <>
            <AppVersionPopup />
            <Divider
              flexItem
              orientation="vertical"
              sx={{
                my: '2px',
                // @ts-ignore
                borderColor: materialTheme?.palette.colors.bg.blue,
              }}
            />
          </>
        )}

        {active ? (
          <Box alignItems="center" display="flex" gap={1}>
            <Text body4 success>
              Active node
            </Text>
            <WorkingNodeLoader />
          </Box>
        ) : (
          <Box alignItems="center" display="flex" gap={1}>
            <CircleIcon
              style={{
                color: 'red',
                fontSize: '15px',
                marginRight: '5px',
              }}
            />
            Node is not running!
          </Box>
        )}
        {canResetNode && (
          <>
            <Divider
              flexItem
              orientation="vertical"
              sx={{
                my: '2px',
                // @ts-ignore
                borderColor: materialTheme?.palette.colors.bg.blue,
              }}
            />

            <div>
              <Typography
                aria-haspopup="true"
                aria-owns={open ? 'mouse-over-popover' : undefined}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                <Button
                  sx={{
                    p: '0',
                    gap: '6px',
                    textTransform: 'capitalize',
                    fontWeight: 600,
                    fontSize: '14px',
                    lineHeight: '20px',
                  }}
                  onClick={onReset}
                >
                  <SyncIcon sx={{ width: '18px', height: '18px' }} />
                  Reset
                </Button>
              </Typography>
              <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                disableRestoreFocus
                id="mouse-over-popover"
                open={open}
                sx={{ pointerEvents: 'none' }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={handlePopoverClose}
              >
                <PopoverContainer>
                  <Text
                    body3
                    sx={{ p: 1, fontWeight: 700, fontFamily: 'Inter' }}
                  >
                    Reset locked nodes
                  </Text>
                </PopoverContainer>
              </Popover>
            </div>
          </>
        )}
      </Box>
    </Box>
  )
}

export default IsNodeActive
