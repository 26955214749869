import React, { useCallback, useMemo, useState } from 'react'

import { useMutation, useQuery } from '@apollo/client'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { Box, Tabs } from '@mui/material'
import { AlertType } from 'components/alert/types'
import { ResetNodeLockModal } from 'components/blocks/modals'
import { WalletConnect } from 'components/blocks/walletConnect'
import ContentBox from 'components/ContentBox'
import { GeneralStats } from 'components/GeneralStats'
import { IsNodeActive } from 'components/isNodeActive'
import { NodesStakingStepper } from 'components/nodeStakingStepper'
import { NodeStats } from 'components/nodeStats'
import Subheader from 'components/Subheader'
import StyledTab from 'components/ui/StyledTab'
import { NODE_POLL_INTERVAL } from 'constants/params'
import { useAppContext } from 'context/AppContext'
import { NODE_BY_ADDRESS } from 'graphql/nodes/queries'
import { NodeData } from 'graphql/nodes/types'
import { REMOVE_VALIDATOR_NODE_APP_ID } from 'graphql/validators/mutation'
import useWeb3 from 'hooks/useWeb3'
import { getProvider } from 'services/provider'
import { getErrorMessage } from 'utils/error'
import { isNodeActive } from 'utils/node'

function RegisterNodePage() {
  const { me, showAlert } = useAppContext()
  const { active } = useWeb3()

  const [tab, setTab] = useState(0)
  const [resetNodeModalOpened, setResetNodeModalOpened] = useState(false)

  const [resetNode] = useMutation(REMOVE_VALIDATOR_NODE_APP_ID)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }

  const { data, refetch } = useQuery(NODE_BY_ADDRESS, {
    variables: { address: me?.stakedNode },
    pollInterval: NODE_POLL_INTERVAL,
    fetchPolicy: 'network-only',
    skip: !me?.stakedNode,
  })

  const nodeData: NodeData = useMemo(() => data?.nodeByAddress, [data])

  const provider = getProvider()

  const walletConnected = useMemo(
    () => active && me?.wallet && provider,
    [active, me, provider],
  )

  const handleResetNode = useCallback(
    async (ok?: boolean) => {
      setResetNodeModalOpened(false)

      if (!ok) return

      try {
        await resetNode()
        showAlert?.({
          isOpen: true,
          title: 'Node(s) successfully reset',
          color: AlertType.SUCCESS,
        })
      } catch (error) {
        showAlert?.({
          isOpen: true,
          title: getErrorMessage(error),
          color: AlertType.ERROR,
        })
      }
    },
    [showAlert, resetNode],
  )

  return (
    <>
      <Subheader py={4}>
        {me?.stakedNode && (
          <IsNodeActive
            active={
              nodeData && isNodeActive(nodeData.active, nodeData.lastActivity)
            }
            canResetNode={tab === 1 && !!nodeData.validator.nodeAppId}
            period={nodeData?.period}
            projectedRewards={nodeData?.projectedRewards?.earning}
            onReset={() => setResetNodeModalOpened(true)}
          />
        )}
      </Subheader>
      <ContentBox>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2.5 }}>
          <Tabs
            indicatorColor="primary"
            sx={{ minHeight: '40px' }}
            value={tab}
            onChange={handleChange}
          >
            <StyledTab
              id="stats-tab-0"
              label="General Stats"
              sx={{
                mr: 4,
              }}
            />
            <StyledTab
              icon={
                !me?.stakedNode ? (
                  <LockOutlinedIcon sx={{ height: '18px', width: '18px' }} />
                ) : undefined
              }
              iconPosition="start"
              id="stats-tab-1"
              label="My nodes"
            />
          </Tabs>
        </Box>
        {tab === 0 && <GeneralStats />}
        {tab === 1 && (
          <>
            {walletConnected ? (
              <>
                {nodeData ? (
                  <NodeStats
                    address={me?.stakedNode as string}
                    nodeData={nodeData}
                    onRefetchNode={refetch}
                  />
                ) : (
                  <NodesStakingStepper />
                )}
              </>
            ) : (
              <WalletConnect />
            )}
          </>
        )}
      </ContentBox>
      <ResetNodeLockModal
        isOpen={resetNodeModalOpened}
        onClose={handleResetNode}
      />
    </>
  )
}

export default RegisterNodePage
