import React, { useMemo } from 'react'

import { useQuery } from '@apollo/client'
import { Popover, Typography } from '@mui/material'
import Text from 'components/ui/Text'
import { DAYS_FOR_UPDATE } from 'constants/params'
import { useAppContext } from 'context/AppContext'
import { APP_VERSIONS } from 'graphql/appVersions/queries'
import { DateTime } from 'luxon'

import { PopoverContainer } from './styles'

function AppVersionPopup() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const { me } = useAppContext()
  const { data } = useQuery(APP_VERSIONS)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const notificationText = useMemo(() => {
    if (!me?.nodeVersion || !data?.appVersions?.cliVersion) return null

    const { cliVersion } = data.appVersions

    if (me.nodeVersion === cliVersion) {
      return { text: "You're up to date", icon: '✅ ' }
    }

    if (me.nodeVersion !== cliVersion) {
      const cliVersionUpdatedAt = DateTime.fromISO(
        data?.appVersions?.cliVersionUpdatedAt,
      )
      const updateDeadline = cliVersionUpdatedAt.plus({ days: DAYS_FOR_UPDATE })

      if (updateDeadline <= DateTime.now()) {
        return {
          text: `Your version is outdated. Upgrade to ${cliVersion} to prevent further downtime.`,
          icon: '⛔',
        }
      }

      return {
        text: `Update to ${cliVersion} before ${updateDeadline.toFormat(
          'MMM dd, yyyy',
        )} to avoid downtime.`,
        icon: '⚠️',
      }
    }

    return null
  }, [data, me])

  if (!me?.nodeVersion) return null

  if (!notificationText)
    return (
      <Text secondary sx={{ fontWeight: 500 }}>
        CLI V{me.nodeVersion}
      </Text>
    )

  return (
    <div>
      <Typography
        aria-haspopup="true"
        aria-owns={open ? 'mouse-over-popover' : undefined}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Text secondary sx={{ fontWeight: 500 }}>
          {notificationText?.icon} CLI V{me.nodeVersion}
        </Text>
      </Typography>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        disableRestoreFocus
        open={open}
        sx={{ pointerEvents: 'none' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
      >
        <PopoverContainer>
          <Text body3 sx={{ p: 1, fontWeight: 700, fontFamily: 'Inter' }}>
            {notificationText.text}
          </Text>
        </PopoverContainer>
      </Popover>
    </div>
  )
}

export default AppVersionPopup
