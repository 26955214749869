import { gql } from '@apollo/client'

export const EARLY_UNSTAKE = gql`
  mutation EarlyUnstake($nodeId: String!) {
    earlyUnstake(nodeId: $nodeId) {
      ok
    }
  }
`

export const REGISTER_VALIDATOR_NODE = gql`
  mutation RegisterValidatorNode($createNodeData: CreateNodeFields!) {
    registerValidatorNode(createNodeData: $createNodeData) {
      address
    }
  }
`
