import React, { useCallback, useMemo, useState } from 'react'
import { useResizeDetector } from 'react-resize-detector'

import { useQuery } from '@apollo/client'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { Box, FormControl, FormControlLabel, RadioGroup } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { CompaniesBox } from 'components/companiesBox'
import { NodesTotalBox } from 'components/NodesTotalBox'
import StyledRadio from 'components/ui/StyledRadio'
import Text from 'components/ui/Text'
import { getFormattedNumber } from 'constants/number'
import {
  SYSTEM_DAILY_STATS_DATASET,
  SYSTEM_DAILY_STATS_GRAPH_DATA,
} from 'graphql/systemDailyStats/queries'
import { DateTime } from 'luxon'

import isUndefined from 'lodash/isUndefined'

import BridgedTokenChart from './Charts/bridgedTokenChart'
import CirculationChart from './Charts/circulationChart'
import NodesBarChart from './Charts/nodesBarChart'
import StakedTokenChart from './Charts/stakedTokenChart'
import ValidatorsBarChart from './Charts/validatorsBarChart'
import { ChartContainer } from './styles'

export interface Range {
  from: DateTime | null
  to: DateTime | null
}

enum BarChartKind {
  NODES = 'nodes',
  VALIDATORS = 'validators',
}

enum AreaChartKind {
  STAKED = 'staked',
  BRIDGED = 'bridged',
}

const DEFAULT_PERIOD_DAYS = 30

function GeneralStats() {
  const [barChartKind, setBarChartKind] = useState(BarChartKind.NODES)
  const [areaChartKind, setAreaChartKind] = useState(AreaChartKind.BRIDGED)

  const [range, setRange] = useState<Range>({
    from: DateTime.now().minus({ days: DEFAULT_PERIOD_DAYS }),
    to: DateTime.now(),
  })

  const { data, loading } = useQuery(SYSTEM_DAILY_STATS_GRAPH_DATA, {
    variables: {
      from: range?.from?.toFormat('yyyy-MM-dd') || undefined,
      to: range?.to?.toFormat('yyyy-MM-dd') || undefined,
    },
  })

  const { ref, width } = useResizeDetector({ handleHeight: false })

  const { data: dataset, loading: datasetLoading } = useQuery(
    SYSTEM_DAILY_STATS_DATASET,
  )

  const systemDailyStatsDataByFields = useMemo(
    () => data?.systemDailyStatsGraphData || {},
    [data],
  )

  const systemDailyStatsDataset = useMemo(
    () => dataset?.systemDailyStatsDataset || {},
    [dataset],
  )

  const renderDiffBlock = useCallback(
    (diff: number, percentage?: number, withoutArrow?: boolean) => {
      if (isUndefined(diff)) return null
      return (
        <Box alignItems="baseline" display="flex">
          {!withoutArrow && !!diff && (
            <div>
              {diff > 0 ? (
                <ArrowUpwardIcon color="success" sx={{ mr: '12px' }} />
              ) : (
                <ArrowDownwardIcon color="error" sx={{ mr: '12px' }} />
              )}
            </div>
          )}
          <Text
            error={diff < 0}
            primary
            success={diff > 0}
            sx={{ mr: 1.5 }}
            title1
          >
            {getFormattedNumber(diff)}
          </Text>
          <Text body0 error={diff < 0} primary success={diff > 0}>
            {percentage ? `(${percentage.toFixed(2)}%)` : ''}
          </Text>
        </Box>
      )
    },
    [],
  )
  // todo in circulation supply i got total supply
  const totalSupply = useMemo(
    () => systemDailyStatsDataset?.circulationSupply || 0,
    [systemDailyStatsDataset],
  )

  const bridgedTokens = useMemo(
    () => systemDailyStatsDataset?.bridgedTokenAmount || 0,
    [systemDailyStatsDataset],
  )

  const stakedTokens = useMemo(
    () => systemDailyStatsDataset?.stackedTokenAmount || 0,
    [systemDailyStatsDataset],
  )

  const handleChangeBarChart = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setBarChartKind((event.target as HTMLInputElement).value as BarChartKind)
    },
    [],
  )

  const handleChangeAreaChart = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAreaChartKind(
        (event.target as HTMLInputElement).value as AreaChartKind,
      )
    },
    [],
  )

  if (loading || datasetLoading)
    return <CircularProgress size={20} sx={{ margin: '75px auto' }} />

  return (
    <>
      <Box display="flex" flexDirection="column" gap={2.5} mb={2.5} ref={ref}>
        <Box
          display="grid"
          gap={2.5}
          gridTemplateColumns="25fr 12fr"
          overflow="hidden"
          width={width || '100%'}
        >
          <Box display="grid" gap={2.5} gridTemplateRows="324fr 148fr">
            <ChartContainer pb={1}>
              <Box display="flex" justifyContent="space-between" p={2}>
                {areaChartKind === AreaChartKind.STAKED ? (
                  <Box>
                    <Text body2 sx={{ mb: 0.5 }}>
                      MNW Staked - Total
                    </Text>
                    <Text body3 secondary sx={{ fontWeight: 300 }}>
                      Number of all tokens staked
                    </Text>
                  </Box>
                ) : (
                  <Box>
                    <Text body2 sx={{ mb: 0.5 }}>
                      MNW Bridged - Total
                    </Text>
                    <Text body3 secondary sx={{ fontWeight: 300 }}>
                      Number of all bridged tokens
                    </Text>
                  </Box>
                )}
                <FormControl>
                  <RadioGroup
                    row
                    sx={{ gap: 3 }}
                    value={areaChartKind}
                    onChange={handleChangeAreaChart}
                  >
                    <FormControlLabel
                      control={<StyledRadio />}
                      label={<Text body3>Bridged</Text>}
                      sx={{ margin: 0 }}
                      value={AreaChartKind.BRIDGED}
                    />
                    <FormControlLabel
                      control={<StyledRadio />}
                      label={<Text body3>Staked</Text>}
                      sx={{ margin: 0 }}
                      value={AreaChartKind.STAKED}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              {areaChartKind === AreaChartKind.STAKED ? (
                <StakedTokenChart
                  chartData={systemDailyStatsDataByFields?.stackedTokenAmount}
                  // note: resolve problem with resize google-chart
                  width={width ? `${(width / 3) * 2 - 20}px` : '100%'}
                />
              ) : (
                <BridgedTokenChart
                  chartData={systemDailyStatsDataByFields?.bridgedTokenAmount}
                  diff={systemDailyStatsDataByFields?.bridgedTokenDailyDiff}
                  // note: resolve problem with resize google-chart
                  width={width ? `${(width / 3) * 2 - 20}px` : '100%'}
                />
              )}
            </ChartContainer>
            <Box display="grid" gap={2.5} gridTemplateColumns="1fr 1fr">
              <ChartContainer p={2}>
                <Text body2 sx={{ mb: 0.5 }}>
                  Running nodes
                </Text>
                <Text body3 secondary sx={{ fontWeight: 300, mb: 3 }}>
                  The number of all running nodes
                </Text>
                <Text primary sx={{ fontWeight: 500 }} title1>
                  {systemDailyStatsDataset?.totalActiveNodesCount || 0}
                </Text>
              </ChartContainer>
              <ChartContainer p={2}>
                <Text body2 sx={{ mb: 0.5 }}>
                  Nodes ++
                </Text>
                <Text body3 secondary sx={{ fontWeight: 300, mb: 3 }}>
                  Nodes added/removed weekly
                </Text>
                <Text primary sx={{ fontWeight: 500 }} title1>
                  {renderDiffBlock(
                    systemDailyStatsDataset?.nodesWeeklyDiff ?? 0,
                    systemDailyStatsDataset?.nodesWeeklyDiffPercentage ?? 0,
                  )}
                </Text>
              </ChartContainer>
            </Box>
          </Box>
          <Box display="grid" gap={2.5} gridTemplateRows="324fr 148fr">
            <ChartContainer
              // note: resolve problem with resize google-chart
              p={2}
              width={width ? `${width / 3}px` : '100%'}
            >
              <Box>
                <Text body2 sx={{ mb: 0.5 }}>
                  MNW Staked - Circul., %
                </Text>
                <Text body3 secondary sx={{ fontWeight: 300, mb: 3 }}>
                  Percentage tokens bridged of circulation supply
                </Text>
              </Box>
              <CirculationChart
                bridgedTokens={bridgedTokens}
                legendTitles={['Staked', 'Bridged', 'Total Supply']}
                stakedTokens={stakedTokens}
                totalSupply={totalSupply}
              />
            </ChartContainer>
            <ChartContainer p={2}>
              <Text body2 sx={{ mb: 0.5 }}>
                MNW Staked - Change
              </Text>
              <Text body3 secondary sx={{ fontWeight: 300, mb: 3 }}>
                A difference in the amount of staked tokens weekly
              </Text>
              <Text primary sx={{ fontWeight: 500 }} title1>
                {renderDiffBlock(
                  systemDailyStatsDataset?.tokensWeeklyDiff ?? 0,
                  systemDailyStatsDataset?.tokensWeeklyDiffPercentage ?? 0,
                )}
              </Text>
            </ChartContainer>
          </Box>
        </Box>
        <ChartContainer height="300px" p={2}>
          <Box display="flex" justifyContent="space-between">
            {barChartKind === BarChartKind.NODES ? (
              <Box>
                <Text body2 sx={{ mb: 0.5 }}>
                  Node Staked - Total
                </Text>
                <Text body3 secondary sx={{ fontWeight: 300 }}>
                  Number of all nodes staked
                </Text>
              </Box>
            ) : (
              <Box>
                <Text body2 sx={{ mb: 0.5 }}>
                  Register validators
                </Text>
              </Box>
            )}

            <FormControl>
              <RadioGroup
                row
                sx={{ gap: 3 }}
                value={barChartKind}
                onChange={handleChangeBarChart}
              >
                <FormControlLabel
                  control={<StyledRadio />}
                  label={<Text body3>Nodes</Text>}
                  sx={{ margin: 0 }}
                  value={BarChartKind.NODES}
                />
                <FormControlLabel
                  control={<StyledRadio />}
                  label={<Text body3>Validators</Text>}
                  sx={{ margin: 0 }}
                  value={BarChartKind.VALIDATORS}
                />
              </RadioGroup>
            </FormControl>
          </Box>
          {barChartKind === BarChartKind.NODES ? (
            <NodesBarChart
              chartDataStaked={systemDailyStatsDataByFields?.stackedNodesCount}
              chartDataUnstaked={
                systemDailyStatsDataByFields?.unstakedNodesCount
              }
            />
          ) : (
            <ValidatorsBarChart
              chartDataClosed={
                systemDailyStatsDataByFields?.closedValidatorsCount
              }
              chartDataRegistered={
                systemDailyStatsDataByFields?.registeredValidatorsCount
              }
            />
          )}
        </ChartContainer>
      </Box>
      <CompaniesBox />
      <NodesTotalBox />
    </>
  )
}

export default React.memo(GeneralStats)
